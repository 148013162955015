import Link from 'next/link';
import { MouseEventHandler } from 'react';

interface NavItemProps {
    text: string,
    href: string,
    active: boolean,
    target: string,
    className?: string,
    onClick?: MouseEventHandler
}

const NavItem = ({ text, href, active, target, className = '', onClick }: NavItemProps) => {
    return (
        (<Link
            href={href}
            target={target}
            className={`text-white/75 text-xl font-medium transition-all duration-500 hover:text-water-500 ${className} ${active ? 'text-water-500' : ''}`}
            onClick={onClick}
        >
            {text}
        </Link>)
    );
};

export default NavItem;