import { AnimatePresence, motion } from 'framer-motion';
import LogoLoader from './LogoLoader';
import { XCircleIcon } from '@heroicons/react/24/solid';

interface FramerModalProps {
    isModalOpen: Boolean,
    setIsModalOpen: Function,
    modalLoading?: Boolean,
    title?: string,
    children?: any,
    maxWidth?: string,
    className?: string,
}

const FramerModal = ({ isModalOpen, setIsModalOpen, modalLoading = false, title, children, maxWidth = 'max-w-4xl', className = '' }: FramerModalProps) => {
    const backdropVariant = {
        hidden: {
            opacity: 0,
            transition: {
                duration: 0.3,
            },
        },
        visible: {
            opacity: 1,
            transition: {
                when: 'beforeChildren',
            },
        },
    };
    const modalVariant = {
        hidden: {
            scale: 0,
            transition: {
                duration: 0.3,
            },
        },
        visible: {
            scale: 1,
            transition: {
                type: 'spring',
                stiffness: 100,
                staggerChildren: 0.5,
            },
        },
    };
    const modalContentVariant = {
        hidden: {
            opacity: 0,
        },
        visible: {
            opacity: 1,
            transition: {
                delayChildren: 0.5,
            },
        },
    };

    return (
        <AnimatePresence>
            {isModalOpen && (
                <motion.div className="fixed inset-0 bg-black/50 filter backdrop-blur-sm flex items-center justify-center z-[100] py-4 overflow-hidden" variants={backdropVariant} initial="hidden" animate="visible" exit="hidden">
                    <motion.div className={`w-full p-4 bg-[#14141F] rounded-3xl border-2 border-gray-200/30 relative mx-3 md:mx-0 text-white ${maxWidth} max-h-full flex flex-col z-[999] ${className}`} variants={modalVariant}>
                        {modalLoading ? <LogoLoader /> : (
                            <>
                                <button className="absolute -top-4 -right-4 text-white" onClick={() => setIsModalOpen(false)}>
                                    <XCircleIcon className="w-10 h-10" />
                                </button>
                                {title && (<motion.h2 className="text-4xl font-white font-aphelium text-white mb-2 uppercase text-center" variants={modalContentVariant}>{title}</motion.h2>)}
                                {children}
                            </>
                        )}
                    </motion.div>
                </motion.div>
            )}
        </AnimatePresence>
    );
};

export default FramerModal;