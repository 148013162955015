/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import ConsentComponent from './Consent';
import FooterComponent from './Footer';
import FramerModal from './FramerModal';
import InfoBanner from './InfoBanner';
import Navbar from './Navbar';
import Image from 'next/image';
import { getCookie, setCookie } from 'cookies-next';
import Timer from './Timer';
import moment from 'moment';

export default function Layout({ children }) {
    const showInfoBanner = false;
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        if (!getCookie('banner')) {
            setIsModalOpen(false);
            setCookie('banner', 1, { maxAge: 60 * 60 * 24 * 7 });
        }
    }, []);

    return (
        <div className="min-h-screen bg-space-500 min-w-full dark">
            <Navbar />
            {showInfoBanner && (
                <div className="relative">
                    <InfoBanner />
                </div>
            )}
            {isModalOpen && (
                <FramerModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} className="bg-[url(/images/modal-bg.webp)] bg-cover bg-right">
                    <div className="flex flex-col min-h-[50vh] justify-between items-start">
                        <div className="flex items-center gap-4 bg-black/50 px-8 -ml-4 rounded-r-2xl">
                            <Image src={'/images/logo-icon.png'} width={825} height={106} alt="Aphelium logo" className="w-20 md:w-24" />
                            <div>
                                <h2 className="text-white text-3xl md:text-5xl font-bold font-aphelium uppercase">Season 4</h2>
                                <h3 className="text-white text-xl md:text-2xl font-medium font-aphelium uppercase">is started</h3>
                            </div>
                        </div>
                        <div className="bg-water-500 rounded-r-2xl px-8 py-2 md:py-4 -ml-4">
                            <h3 className="text-white text-3xl md:text-6xl uppercase font-bold">3K USD<br/>3B $WUF<br/>2M $AFG!</h3>
                        </div>
                        <div className="bg-black/50 rounded-r-2xl px-8 py-2 -ml-4">
                            Finishing in <Timer className="font-bold uppercase" expiryTimestamp={moment('2024-08-03 07:00:00').toDate()} />
                        </div>
                    </div>
                </FramerModal>
            )}
            <main className="relative">
                {children}
            </main>
            <FooterComponent />
            <ConsentComponent />
        </div>
    );
}