import Image from 'next/image';
import { useEffect, useMemo, useState } from 'react';

const LogoLoader = () => {
    const sentences = useMemo(() => [
        'Initializing the cosmic microwave popcorn machine...',
        'Putting on our space helmets...',
        'Activating the anti-gravity boots...',
        'Testing out the laser guns...',
        'Activating the holographic star map...',
        'Stepping into the teleportation pod...',
        'Testing out the hyperspace jump...',
        'Warming up the intergalactic engines...',
        'Equipping the spacesuit...',
    ], []);
    const [randomSentence, setRandomSentence] = useState(sentences[Math.floor(Math.random() * sentences.length)]);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setRandomSentence(sentences[Math.floor(Math.random() * sentences.length)]);
        }, 3500);

        return () => {
            clearInterval(intervalId);
        };
    }, [sentences]);

    return (
        <div className="flex items-center justify-center h-full">
            <div className="flex flex-col items-center">
                <Image src={'/images/aph-loader.svg'} width={500} height={500} alt="Loader" className="w-24 h-24" />
                <span className="text-white">{randomSentence}</span>
            </div>
        </div>
    );
};

export default LogoLoader;