// import React, { ReactNode } from 'react';
// import useEmblaCarousel, { EmblaOptionsType } from 'embla-carousel-react';
// import { NextButton, PrevButton, usePrevNextButtons } from './CarouselControls';

// type Props = EmblaOptionsType & { children: null | ReactNode };

// const Carousel = ({ children, ...options }: Props) => {
//     const [emblaRef, emblaApi] = useEmblaCarousel(options);

//     const {
//         prevBtnDisabled,
//         nextBtnDisabled,
//         onPrevButtonClick,
//         onNextButtonClick,
//     } = usePrevNextButtons(emblaApi);

//     return (
//         <div className="flex gap-4">
//             <PrevButton disabled={prevBtnDisabled} onClick={onPrevButtonClick} />
//             <div className="overflow-hidden flex-1" ref={emblaRef}>
//                 <div className="flex">{children}</div>
//             </div>
//             <NextButton disabled={nextBtnDisabled} onClick={onNextButtonClick} />
//         </div>
//     );
// };

// export default Carousel;
import { useKeenSlider } from 'keen-slider/react';
import 'keen-slider/keen-slider.min.css';
import { useState } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';

const Carousel = ({ children }) => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const [loaded, setLoaded] = useState(false);

    const [sliderRef, instanceRef] = useKeenSlider({
        initial: 0,
        slideChanged(slider) {
            setCurrentSlide(slider.track.details.rel);
        },
        created() {
            setLoaded(true);
        },
    });

    return (
        <>
            <div ref={sliderRef} className="keen-slider">
                {children.map(c => (
                    <div className="keen-slider__slide" key={c}>{c}</div>
                ))}
            </div>
            {loaded && instanceRef.current && (
                <div className="flex justify-between mt-2">
                    <button
                        onClick={(e: any) => {
                            e.stopPropagation() || instanceRef.current.prev();
                        }}
                        disabled={currentSlide === 0}
                        type="button"
                        className="disabled:opacity-0"
                    >
                        <ChevronLeftIcon className="w-5 h-5 text-white" />
                    </button>
                    <button
                        onClick={(e: any) => {
                            e.stopPropagation() || instanceRef.current.next();
                        }}
                        disabled={currentSlide === instanceRef.current.track.details.slides.length - 1}
                        type="button"
                        className="disabled:opacity-0"
                    >
                        <ChevronRightIcon className="w-5 h-5 text-white" />
                    </button>
                </div>
            )}
        </>
    );
};

export default Carousel;