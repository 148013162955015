/* eslint-disable no-unused-vars */
import Image from 'next/image';
import { useEffect, useState } from 'react';

const ApValue = () => {
    const [apValue, setApValue] = useState(null);
    const [textClass, setTextClass] = useState('white');

    const pulseText = (cls) => {
        setTextClass(cls);
        setTimeout(() => {
            setTextClass('white');
        }, 1000);
    };

    useEffect(() => {
        setInterval(() => {
            fetch('/api/apvalue')
                .then(response => response.json())
                .then(json => setApValue((oldVal) => {
                    if (oldVal > json.value) pulseText('red-400');
                    else if (oldVal < json.value) pulseText('green-400');
                    return json.value;
                }));
        }, 5000);
    }, []);

    return (
        <div className={`text-${textClass} flex items-center gap-1`}>
            <Image src={'/images/sprites/coin.png'} alt='AP coin' width={128} height={128} className="w-5" />
            <span>ND</span>
        </div>
    );
};

export default ApValue;