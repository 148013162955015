import { XMarkIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import FramerModal from './FramerModal';
import Carousel from './Carousel/Carousel';

export default function InfoBanner() {
    const [open, setOpen] = useState(true);
    const [modalOpen, setModalOpen] = useState(false);

    return (
        <>
            {open && (
                <>
                    {modalOpen && (<FramerModal isModalOpen={modalOpen} setIsModalOpen={setModalOpen} maxWidth="max-w-xs md:max-w-xl">
                        <h2 className="text-4xl md:text-6xl text-orange-500 font-halloween uppercase text-center mb-8">🎃 Halloween Event</h2>
                        <Carousel>
                            <div className="flex-[0_0_100%]">
                                <h3 className="font-halloween text-2xl text-green-500 uppercase mb-2">1. Play during the event</h3>
                                <p className="mb-1">Play the game from 31st October at 06:00 UTC to 5th November at 20:00 UTC.</p>
                                <p>Buy a bunch of Exploration Tickets, use some Alien Seducers and get ready for the party! 🎉</p>
                            </div>
                            <div className="flex-[0_0_100%]">
                                <h3 className="font-halloween text-2xl text-green-500 uppercase mb-2">2. Find the Halloween aliens</h3>
                                <p className="mb-1">During your explorations, you may step into some special Halloween encounters.</p>
                                <p>Fulfill the alien request and have a chance to obtain an Halloween Cookie!</p>
                            </div>
                            <div className="flex-[0_0_100%]">
                                <h3 className="font-halloween text-2xl text-green-500 uppercase mb-2">3. Use your cookies</h3>
                                <p className="mb-1">You can use your cookies in 3 ways:</p>
                                <ol className="list-decimal list-inside ml-2 spaxe-y-1">
                                    <li><code>/consume</code> them, you will get 13 minutes of &quot;Boo power&quot;, causing aliens to fly away, assuring a safe exploration</li>
                                    <li>Use a cookie during an encounter using the <em>Boo</em> button, the alien will be scared and the encounter will succeed</li>
                                    <li>Once reached 13 cookies you can mint an NFT for future use!</li>
                                </ol>
                            </div>
                            <div className="flex-[0_0_100%]">
                                <h3 className="font-halloween text-2xl text-green-500 uppercase mb-2">4. Try your luck with the blend</h3>
                                <p className="mb-1">Want to feel the &quot;Trick or treat&quot; essence? Try your luck in the special Halloween NTT Blend! 👀</p>
                                <p>You can blend 2 stickers to have a chance to obtain the NTT but be aware, the chance to get it are just 13%!</p>
                            </div>
                        </Carousel>
                    </FramerModal>)}
                    <div className="fixed bottom-5 z-20 left-10 right-10 rounded-lg isolate flex items-center gap-x-6 overflow-hidden bg-purple-600 text-white border border-purple-800 px-6 py-2.5 sm:px-3.5 sm:before:flex-1">
                        <div
                            className="absolute left-[max(-7rem,calc(50%-52rem))] top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-2xl"
                            aria-hidden="true"
                        >
                            <div
                                className="aspect-[577/310] w-[36.0625rem] bg-gradient-to-r from-[#ff80b5] to-[#9089fc] opacity-30"
                                style={{
                                    clipPath:
                                'polygon(74.8% 41.9%, 97.2% 73.2%, 100% 34.9%, 92.5% 0.4%, 87.5% 0%, 75% 28.6%, 58.5% 54.6%, 50.1% 56.8%, 46.9% 44%, 48.3% 17.4%, 24.7% 53.9%, 0% 27.9%, 11.9% 74.2%, 24.9% 54.1%, 68.6% 100%, 74.8% 41.9%)',
                                }}
                            />
                        </div>
                        <div
                            className="absolute left-[max(45rem,calc(50%+8rem))] top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-2xl"
                            aria-hidden="true"
                        >
                            <div
                                className="aspect-[577/310] w-[36.0625rem] bg-gradient-to-r from-[#ff80b5] to-[#9089fc] opacity-30"
                                style={{
                                    clipPath:
                                    'polygon(74.8% 41.9%, 97.2% 73.2%, 100% 34.9%, 92.5% 0.4%, 87.5% 0%, 75% 28.6%, 58.5% 54.6%, 50.1% 56.8%, 46.9% 44%, 48.3% 17.4%, 24.7% 53.9%, 0% 27.9%, 11.9% 74.2%, 24.9% 54.1%, 68.6% 100%, 74.8% 41.9%)',
                                }}
                            />
                        </div>
                        <div className="flex flex-wrap items-center gap-x-4 gap-y-2">
                            <p className="text-md leading-6 text-white text-left">
                                Halloweeen event <strong className="font-bold">live</strong> from 31st October 06:00 UTC to 5th November 20:00 UTC!
                            </p>
                            <button
                                onClick={() => setModalOpen(true)}
                                className="flex-none rounded-full bg-gray-900 px-3.5 py-1 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
                            >
                            Find out more <span aria-hidden="true">&rarr;</span>
                            </button>
                        </div>
                        <div className="flex flex-1 justify-end">
                            <button type="button" className="-m-3 p-3 focus-visible:outline-offset-[-4px]">
                                <span className="sr-only">Dismiss</span>
                                <XMarkIcon className="h-5 w-5 text-white" aria-hidden="true" onClick={() => setOpen(false)}/>
                            </button>
                        </div>
                    </div>
                </>
            )}
        </>
    );
}
