import { useEffect, useState } from 'react';
import { setCookie, hasCookie } from 'cookies-next';
import Image from 'next/image';
import ModalComponent from './Modal';
import toast from 'react-hot-toast';
import Link from 'next/link';

const ConsentComponent = () => {
    const [consent, setConsent] = useState(true);
    const [cookieDialog, setCookieDialog] = useState(false);
    const [cookieRoleCode, setCookieRoleCode] = useState(null);

    useEffect(() => {
        setConsent(hasCookie('localConsent'));
    }, []);

    const acceptCookie = async () => {
        setCookie('localConsent', 'true', { maxAge: 60 * 60 * 24 * 365 });
        // eslint-disable-next-line no-undef
        gtag('consent', 'update', {
            ad_storage: 'granted',
            analytics_storage: 'granted',
        });
        const response = await fetch('/api/cookielegend', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        if (response.ok) {
            const { data: code } = await response.json();
            setCookieRoleCode(code.code);
            setCookieDialog(true);
        }
        setConsent(true);
    };

    const closeP = () => {
        setConsent(true);
    };

    const denyCookie = () => {
        setConsent(true);
        setCookie('localConsent', 'false', { maxAge: 60 * 60 * 24 * 365 });
    };

    const copyText = (text) => {
        navigator.clipboard.writeText(text);
        toast.success('Text copied!');
    };

    return <>
        {!consent && (
            <div className={`fixed bottom-3 md:right-3 mx-1 md:mx-0 max-w-md rounded-md bg-zinc-200 ${consent ? 'hidden' : ''} text-zinc-900 overflow-hidden`}>
                <div className="flex items-center gap-2 p-3">
                    <div className="flex-grow">
                        <Image src="/images/astro-cookie.png" width={200} height={305} alt="astronaut cookie"></Image>
                        <a href="https://it.freepik.com/vettori-gratuito/carino-astronauta-giaceva-sulla-luna-icona-del-fumetto-illustrazione-concetto-dell-icona-di-scienza-tecnologia-isolato-stile-cartone-animato-piatto_10717940.htm#query=astronaut%20cookie&position=38&from_view=search&track=ais" target="_blank" rel="noreferrer" title="image credits" className='text-xs font-light hidden'>Credits to @catalyststuff</a>
                    </div>
                    <div className="flex-shrink">
                        <p className="text-md font-light">Uh oh !</p>
                        <p className="text-xl font-bold mb-2">Yet another cookie banner...</p>
                        <p className="text-sm text-light">
                            It&apos;s the law! We have to ask your consent to use all your juicy data, what a shame.
                            <span className="font-bold">Just kidding</span>, we use cookies <span className="font-bold">only</span> to assure the correct site behavior and to know how many amazing astronauts visit it! More info in our <Link
                                href={'/privacy-policy'}
                                className="text-blue-700 hover:underline"
                                title="Privacy policy">Privacy Policy</Link>.
                            If you don&apos;t mind, please accept them 🤟🏽
                        </p>
                    </div>
                </div>
                <div className="flex align-middle justify-between bg-white divide-x divide-zinc-400">
                    <button onClick={() => closeP()} className="flex-1 py-2 cursor-pointer hover:bg-water-500 hover:text-white transition-colors duration-300">Close</button>
                    <button onClick={() => denyCookie()} className="flex-1 py-2 cursor-pointer hover:bg-red-600 hover:text-white transition-colors duration-300">Deny all</button>
                    <button onClick={() => acceptCookie()} className="flex-1 py-2 cursor-pointer hover:bg-emerald-500 hover:text-white transition-colors duration-300">Accept all</button>
                </div>
            </div>
        )}

        <ModalComponent title="You legend 🤘" isOpen={cookieDialog} setIsOpen={setCookieDialog}>
            <p className="mb-2">Thanks for accepting cookies!</p>
            <Image src="/images/astro-happy.png" alt="You legend" layout="responsive" width={400} height={400}/>
            <p className="my-2">You earned the <span className="font-bold">CookieLegend</span> role in our Discord server! Copy and paste the code below in the #commands channel!</p>
            <code className="bg-gray-900 block px-2 py-1 text-gray-200" onClick={() => copyText(`!getrole ${cookieRoleCode}`)}>
                !getrole {cookieRoleCode}
            </code>
            <div className="flex justify-end">
                <button type="button" className="cursor-pointer mt-3 rounded-md px-4 py-2 text-sm font-medium border border-water-500 text-water-500 bg-transparent hover:bg-water-500 hover:border-transparent hover:text-white transition-all duration-300 focus:outline-none" onClick={() => setCookieDialog(false)}>
                    Got it!
                </button>
            </div>
        </ModalComponent>
    </>;
};

export default ConsentComponent;