import Image from 'next/image';
import Link from 'next/link';
import logo from '../public/images/logo.png';
import { deleteCookie } from 'cookies-next';

const FooterComponent = () => {
    const resetCookies = () => {
        deleteCookie('localConsent');
        window.location.reload();
    };

    return (
        <footer className="py-12 md:py-32 bg-black">
            <div className="container mx-auto px-4 md:px-0 grid grid-cols-1 md:grid-cols-4 gap-6 md:gap-12 text-white">
                <div className="flex flex-col gap-4">
                    <Image src={logo} alt="Aphelium logo" />
                    <div className="text-sm text-gray-400">
                        <p className="text-base mb-2 text-white">&copy; 2024 Aphelium by</p>
                        <p>Castellini Andrea</p>
                        <p>Via Luigi Pirandello 2A, Castiglione delle Stiviere, Italy</p>
                        <p>VAT: 02719620201</p>
                    </div>
                </div>
                <div>
                    <p className="text-lg font-medium mb-4">Main menu</p>
                    <ul className="space-y-2 font-light">
                        <li>
                            <Link href={'/'}>
                                Home
                            </Link>
                        </li>
                        <li>
                            <a href="https://cdn.aphelium.io/files/WhitePaper.pdf" target="_blank" rel="noreferrer">
                                Whitepaper
                            </a>
                        </li>
                        <li>
                            <Link href={'/contact-us'}>
                                Contact us
                            </Link>
                        </li>
                    </ul>
                </div>
                <div>
                    <p className="text-lg font-medium mb-4">User menu</p>
                    <ul className="space-y-2 font-light">
                        <li>
                            <Link href={'/privacy-policy'}>
                                Privacy Policy
                            </Link>
                        </li>
                        <li onClick={() => resetCookies()} className="cursor-pointer">
                            <span>Manage cookies</span>
                        </li>
                    </ul>
                </div>
                <div>
                    <p className="text-lg font-medium mb-4 md:text-right">Follow us</p>
                    <div className="flex items-center md:justify-end gap-2">
                        <a href="https://discord.gg/hanatZPWFk" aria-label="Join our Discord server" target="_blank" rel="nofollow noreferrer" className="p-4 bg-zinc-800 rounded-lg w-14 h-14 flex items-center justify-center hover:bg-water-500 transition-colors duration-300 cursor-pointer">
                            <svg width="24" height="24" fill="#ffffff" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title>Discord</title><path d="M20.317 4.3698a19.7913 19.7913 0 00-4.8851-1.5152.0741.0741 0 00-.0785.0371c-.211.3753-.4447.8648-.6083 1.2495-1.8447-.2762-3.68-.2762-5.4868 0-.1636-.3933-.4058-.8742-.6177-1.2495a.077.077 0 00-.0785-.037 19.7363 19.7363 0 00-4.8852 1.515.0699.0699 0 00-.0321.0277C.5334 9.0458-.319 13.5799.0992 18.0578a.0824.0824 0 00.0312.0561c2.0528 1.5076 4.0413 2.4228 5.9929 3.0294a.0777.0777 0 00.0842-.0276c.4616-.6304.8731-1.2952 1.226-1.9942a.076.076 0 00-.0416-.1057c-.6528-.2476-1.2743-.5495-1.8722-.8923a.077.077 0 01-.0076-.1277c.1258-.0943.2517-.1923.3718-.2914a.0743.0743 0 01.0776-.0105c3.9278 1.7933 8.18 1.7933 12.0614 0a.0739.0739 0 01.0785.0095c.1202.099.246.1981.3728.2924a.077.077 0 01-.0066.1276 12.2986 12.2986 0 01-1.873.8914.0766.0766 0 00-.0407.1067c.3604.698.7719 1.3628 1.225 1.9932a.076.076 0 00.0842.0286c1.961-.6067 3.9495-1.5219 6.0023-3.0294a.077.077 0 00.0313-.0552c.5004-5.177-.8382-9.6739-3.5485-13.6604a.061.061 0 00-.0312-.0286zM8.02 15.3312c-1.1825 0-2.1569-1.0857-2.1569-2.419 0-1.3332.9555-2.4189 2.157-2.4189 1.2108 0 2.1757 1.0952 2.1568 2.419 0 1.3332-.9555 2.4189-2.1569 2.4189zm7.9748 0c-1.1825 0-2.1569-1.0857-2.1569-2.419 0-1.3332.9554-2.4189 2.1569-2.4189 1.2108 0 2.1757 1.0952 2.1568 2.419 0 1.3332-.946 2.4189-2.1568 2.4189Z"/></svg>
                        </a>
                        <a href="https://twitter.com/ApheliumGame" aria-label="Follow our Twitter account" target="_blank" rel="nofollow noreferrer" className="p-4 bg-zinc-800 rounded-lg w-14 h-14 flex items-center justify-center hover:bg-water-500 transition-colors duration-300 cursor-pointer">
                            <svg width="24" height="24" role="img" fill="#ffffff" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title>X</title><path d="M18.901 1.153h3.68l-8.04 9.19L24 22.846h-7.406l-5.8-7.584-6.638 7.584H.474l8.6-9.83L0 1.154h7.594l5.243 6.932ZM17.61 20.644h2.039L6.486 3.24H4.298Z"/></svg>
                        </a>
                        <a href="https://aphelium.medium.com" aria-label="Read our latest news on Medium" target="_blank" rel="nofollow noreferrer" className="p-4 bg-zinc-800 rounded-lg w-14 h-14 flex items-center justify-center hover:bg-water-500 transition-colors duration-300 cursor-pointer">
                            <svg width="24" height="24" fill="#ffffff" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title>Medium</title><path d="M13.54 12a6.8 6.8 0 01-6.77 6.82A6.8 6.8 0 010 12a6.8 6.8 0 016.77-6.82A6.8 6.8 0 0113.54 12zM20.96 12c0 3.54-1.51 6.42-3.38 6.42-1.87 0-3.39-2.88-3.39-6.42s1.52-6.42 3.39-6.42 3.38 2.88 3.38 6.42M24 12c0 3.17-.53 5.75-1.19 5.75-.66 0-1.19-2.58-1.19-5.75s.53-5.75 1.19-5.75C23.47 6.25 24 8.83 24 12z"/></svg>
                        </a>
                        <a href="https://twitch.tv/apheliumgame" aria-label="Watch our streams on Twitch" target="_blank" rel="nofollow noreferrer" className="p-4 bg-zinc-800 rounded-lg w-14 h-14 flex items-center justify-center hover:bg-water-500 transition-colors duration-300 cursor-pointer">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 300 300"><path fillRule="evenodd" clipRule="evenodd" fill="#ffffff" d="M215.2 260.8h-58.7L117.4 300H78.3v-39.2H6.6V52.2L26.1 0h267.3v182.6l-78.2 78.2zm52.2-91.2V26.1H52.2v189.1h58.7v39.1l39.1-39.1h71.7l45.7-45.6z"/><path fill="#ffffff" d="M195.6 78.3v78.3h26.1V78.3h-26.1zm-71.7 78.2H150V78.3h-26.1v78.2z"/></svg>
                        </a>
                    </div>
                    <div className="flex justify-end mt-4">
                        <div id="tree-nation-offset-website"></div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default FooterComponent;