import Image from 'next/image';
import waxImage from '../public/images/cloud-wallet.svg';
import anchorLogo from '../public/images/anchor-logo.svg';
import anchorText from '../public/images/anchor-text.svg';
import { useAuthFunctions } from '@cryptopuppie/next-waxauth';
import { toast } from 'react-hot-toast';
import Link from 'next/link';

interface WalletLinkProps {
    closeModal?: Function;
}

const WalletLink = ({ closeModal }: WalletLinkProps) => {
    const { loginWithCloudWallet, loginWithAnchor } = useAuthFunctions();

    const login = async provider => {
        try {
            if (provider === 'anchor') {
                await loginWithAnchor();
            }
            else {
                await loginWithCloudWallet();
            }
            closeModal && closeModal(false);
        }
        catch (err) {
            toast.error(err.message);
        }
    };

    return (
        <div className="flex flex-col gap-4">
            {process.env.NEXT_PUBLIC_NETWORK === 'test' && (
                <div className="p-4 border border-orange-500 text-orange-500 rounded-2xl font-medium"><p>We are currently in testnet phase, only Anchor is supported and you need a testnet wallet. If you don&apos;t have one you can create it from <Link href={'/wax-testnet-configurator'} className="font-bold underline">here</Link>.</p></div>
            )}
            <button onClick={() => login('cloudwallet')} className="bg-gradient-to-r from-purple-700 to-cyan-400 rounded-full px-10 py-2 disabled:opacity-30 disabled:cursor-not-allowed" disabled={process.env.NEXT_PUBLIC_NETWORK === 'test'}>
                <Image src={waxImage} alt="Wax connect" className="w-full" />
            </button>
            <button onClick={() => login('anchor')} className="flex items-center bg-[#3650a2] md:px-10 px-2 py-2 rounded-full">
                <Image src={anchorLogo} alt="Anchor logo" className="h-8 md:h-12" />
                <Image src={anchorText} alt="Anchor text" className="h-6 md:h-10" />
            </button>
        </div>
    );
};

export default WalletLink;