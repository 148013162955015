/* eslint-disable no-undef */
import '../styles/global.css';
import { AppProps } from 'next/app';
import { SessionProvider } from 'next-auth/react';
import Layout from '../components/Layout';
import { Toaster } from 'react-hot-toast';
require('../lib/bigint');
import { DefaultSeo } from 'next-seo';
import Script from 'next/script';
import { getCookie } from 'cookies-next';
import WaxAuthProvider from '@cryptopuppie/next-waxauth';
import localFont from 'next/font/local';
import { useEffect } from 'react';
import { useRouter } from 'next/router';
import FingerPrintJS from '@fingerprintjs/fingerprintjs';
const spaco = localFont({
  src: [{
    path: '../public/fonts/SpacoStencilSCNormal/font.woff2',
    weight: '500'
  }, {
    path: '../public/fonts/SpacoStencilSCThin/font.woff2',
    weight: '300'
  }]
});
const halloweenToo = localFont({
  src: [{
    path: '../public/fonts/HalloweenToo/HalloweenToo.woff2',
    weight: '500'
  }]
});
const App = ({
  Component,
  pageProps: {
    session,
    ...pageProps
  }
}: AppProps) => {
  const consent = getCookie('localConsent');
  const endpoint = process.env.NEXT_PUBLIC_RPC_ENDPOINT;
  const chainId = process.env.NEXT_PUBLIC_CHAIN_ID;
  const router = useRouter();
  const poll = async () => {
    const fpPromise = await FingerPrintJS.load({
      monitoring: false
    });
    const fp = await fpPromise.get();
    await fetch('/api/poll', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        token: fp.visitorId
      })
    });
  };
  useEffect(() => {
    poll().then();
  }, [router.asPath]);

  // poll().then();

  return <>
            <style jsx global>{`
            :root {
            /* ... */
            --spaco-font: ${spaco.style.fontFamily};
            --halloween-font: ${halloweenToo.style.fontFamily}
            }
            `}</style>
            <SessionProvider session={session} basePath={process.env.NEXT_PUBLIC_AUTH_URL}>
                <WaxAuthProvider net={{
        endpoint,
        chainId,
        dApp: 'Aphelium'
      }}>
                    <DefaultSeo additionalLinkTags={[{
          rel: 'apple-touch-icon',
          href: '/images/favicons/apple-icon-57x57.png',
          sizes: '57x57'
        }, {
          rel: 'apple-touch-icon',
          href: '/images/favicons/apple-icon-60x60.png',
          sizes: '60x60'
        }, {
          rel: 'apple-touch-icon',
          href: '/images/favicons/apple-icon-72x72.png',
          sizes: '72x72'
        }, {
          rel: 'apple-touch-icon',
          href: '/images/favicons/apple-icon-76x76.png',
          sizes: '76x76'
        }, {
          rel: 'apple-touch-icon',
          href: '/images/favicons/apple-icon-114x114.png',
          sizes: '114x114'
        }, {
          rel: 'apple-touch-icon',
          href: '/images/favicons/apple-icon-120x120.png',
          sizes: '120x120'
        }, {
          rel: 'apple-touch-icon',
          href: '/images/favicons/apple-icon-144x144.png',
          sizes: '144x144'
        }, {
          rel: 'apple-touch-icon',
          href: '/images/favicons/apple-icon-152x152.png',
          sizes: '152x152'
        }, {
          rel: 'apple-touch-icon',
          href: '/images/favicons/apple-icon-180x180.png',
          sizes: '180x180'
        }, {
          rel: 'icon',
          type: 'image/png',
          sizes: '192x192',
          href: '/images/favicons/android-icon-192x192.png'
        }, {
          rel: 'icon',
          type: 'image/png',
          sizes: '32x32',
          href: '/images/favicons/favicon-32x32.png'
        }, {
          rel: 'icon',
          type: 'image/png',
          sizes: '96x96',
          href: '/images/favicons/favicon-96x96.png'
        }, {
          rel: 'icon',
          type: 'image/png',
          sizes: '16x16',
          href: '/images/favicons/favicon-16x16.png'
        }, {
          rel: 'manifest',
          href: '/images/favicons/manifest.json'
        }]} additionalMetaTags={[{
          name: 'msapplication-TileColor',
          content: '#FFFFFF'
        }, {
          name: 'msapplication-TileImage',
          content: '/images/favicons/ms-icon-144x144.png'
        }, {
          name: 'theme-color',
          content: '#FFFFFF'
        }]} openGraph={{
          type: 'website',
          locale: 'en_EN',
          url: 'https://aphelium.io',
          siteName: 'Aphelium'
        }} twitter={{
          site: '@ApheliumGame',
          cardType: 'summary_large_image'
        }} titleTemplate="Aphelium | %s" />

                    {process.env.NEXT_PUBLIC_GTAG && <Script id="gtag" strategy="afterInteractive" dangerouslySetInnerHTML={{
          __html: `
                                    window.dataLayer = window.dataLayer || [];
                                    function gtag(){dataLayer.push(arguments);}
                                    gtag('consent', 'default', {
                                        'ad_storage': 'denied',
                                        'analytics_storage': 'denied'
                                    });
                                    gtag('config', 'AW-11227133364');
                                    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                                        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                                        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                                        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                                        })(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GTAG}');`
        }} />}

                    {process.env.NEXT_PUBLIC_CLARITY_ID && <Script id="mc" strategy="afterInteractive" dangerouslySetInnerHTML={{
          __html: `
                                    (function(c,l,a,r,i,t,y){
                                        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                                        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                                        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
                                    })(window, document, "clarity", "script", "${process.env.NEXT_PUBLIC_CLARITY_ID}");
                                `
        }} />}

                    {process.env.NEXT_PUBLIC_GTAG && consent && <Script id="consupd" strategy="afterInteractive" dangerouslySetInnerHTML={{
          __html: `
                                    gtag('consent', 'update', {
                                        'ad_storage': 'granted',
                                        'analytics_storage': 'granted'
                                    });
                                `
        }} />}
                    {process.env.NEXT_PUBLIC_UMAMI_SITE_ID && <script async src="https://analytics.umami.is/script.js" data-website-id={process.env.NEXT_PUBLIC_UMAMI_SITE_ID}></script>}
                    <Script onLoad={() => TreeNationOffsetWebsite({
          code: '653a76890649c',
          lang: 'en',
          theme: 'dark'
        }).render('#tree-nation-offset-website')} src="https://widgets.tree-nation.com/js/widgets/v1/widgets.min.js?v=1.0"></Script>
                    <Toaster />
                    <Layout>
                        <Component {...pageProps} />
                    </Layout>
                </WaxAuthProvider>
            </SessionProvider>
        </>;
};
export default App;