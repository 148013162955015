import { useTimer } from 'react-timer-hook';

interface TimerProps {
    expiryTimestamp: Date,
    className?: string,
}

const Timer = ({ expiryTimestamp, className = '' }: TimerProps) => {
    const {
        minutes,
        hours,
        days,
        seconds,
    } = useTimer({
        expiryTimestamp,
    });

    return (
        <span className={className}>
            {days}d {hours}h {minutes}m {seconds}s
        </span>
    );
};

export default Timer;